/* eslint-disable no-param-reassign */
// // eslint-disable-next-line react-hooks/exhaustive-deps

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_COMMON, API_GET_USERS, API_USER,API_DEACTIVATE_USER,API_REACTIVATE_USER } from '../../constants/apiUrls';
import { apiGetData, apiUpdateData,apiPostData, } from '../../services/api.service';



const initialState = {
    user: null,
    loading: 'idle', // 'idle' | 'pending'
    error: null,
    isUpdated: false,
    users: [],
    totalCount: 0,
    isDeleted:false,
    modulePermissions: {}

};


export const getUserById = createAsyncThunk('@user/getuserbyId', async (userId, thunkAPI) => {
    const url = `${API_USER}/${userId}`;
    try {
        const response = await apiGetData(url);
        const resposeData = response.data?.response;
        return resposeData
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
});

export const updateUserById = createAsyncThunk('@user/updateUserById', async (data, thunkAPI) => {
    const url = `${API_USER}/${data.userId}`;
    try {
        const response = await apiUpdateData(url, data.data);
        const resposeData = response.data?.response;
        // store.dispatch(showToaster({type: 'success', text: 'Updated user details!.'}))
        return resposeData.message
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
});

export const getAllUsers = createAsyncThunk('@user/getAllUsers', async (params, thunkAPI) => {
    const url = `${API_COMMON}/${API_GET_USERS}`;
    try {
        const response = await apiGetData(url, params);
        const { users, totalCount } = response.data?.response;
        return { users, totalCount };
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})

export const deactivateUser = createAsyncThunk('@user/deactivate', async (data, thunkAPI) => {
    const url = `${API_COMMON}/${API_DEACTIVATE_USER}`;
    try {
        const response = await apiPostData(url, data.data);
        const resposeData = response.data?.response;
        return resposeData;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})

export const reactivateUser = createAsyncThunk('@user/Reactivate', async (data, thunkAPI) => {
    const url = `${API_COMMON}/${API_REACTIVATE_USER}`;
    try {
        const response = await apiPostData(url, data.data);
        const resposeData = response.data?.response;
        return resposeData;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data)
    }
})


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clearUserState: (state) => {
            state.user = null;
            state.users = [];
            state.totalCount = 0;

        },


    },
    extraReducers: (builder) => {
        builder.addCase(getUserById.pending, (state) => {
            state.loading = 'pending';
            state.isUpdated = false;
        })
        builder.addCase(getUserById.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.user = action.payload
            const permissions = action.payload?.permissions?.reduce((obj, curr) => ({...obj,[curr.module]: curr.access}),{});
            state.modulePermissions = permissions;
        })
        builder.addCase(getUserById.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload
        })

        builder.addCase(updateUserById.pending, (state) => {
            state.loading = 'pending';
            state.isUpdated = false;
        })
        builder.addCase(updateUserById.fulfilled, (state, action) => {
            state.loading = 'idle';
            if (action.payload) {
                state.isUpdated = true;
            }
            state.user = action.payload
        })
        builder.addCase(updateUserById.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;
            state.isUpdated = false;
        })
        builder.addCase(getAllUsers.pending, (state) => {
            state.loading = 'pending';
        })
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.loading = 'idle';
            state.users = action.payload.users;
            state.totalCount = action.payload.totalCount;
        })
        builder.addCase(getAllUsers.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;

        })
        builder.addCase(deactivateUser.pending, (state) => {
            state.loading = 'pending';
            state.isUpdated = false;
        })
        builder.addCase(deactivateUser.fulfilled, (state, action) => {
            state.loading = 'idle';
            if (action.payload) {
                state.isUpdated = true;
            }
        })
        builder.addCase(deactivateUser.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;
        })
        builder.addCase(reactivateUser.pending, (state) => {
            state.loading = 'pending';
            state.isUpdated = false;
        })
        builder.addCase(reactivateUser.fulfilled, (state, action) => {
            state.loading = 'idle';
            if (action.payload) {
                state.isUpdated = true;
            }
        })
        builder.addCase(reactivateUser.rejected, (state, action) => {
            state.loading = 'idle';
            state.error = action.payload;
        })
    }
});

// selectors
export const userSelector = (state) => state.user.user;
export const userUpdatedSelector = (state) => state.user.isUpdated;
export const getAllUserSelector = (state) => state.user && state.user.users;
export const getTotalCountSelector = (state) => state.user.totalCount;
export const modulePermissionSelector = (state) => state.user.modulePermissions;




// export const {} = userSlice.actions
export const { clearUserState } = userSlice.actions;


export default userSlice.reducer;
