/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { API_COMMON, API_GET_ALL_ADMINS, API_REACTIVATE_USER, API_DEACTIVATE_USER } from '../../constants/apiUrls';
import { apiGetData, apiPostData, apiUpdateData } from '../../services/api.service';
import { useDispatch } from "react-redux";

import { showToaster } from "src/redux/slice/toasterSlice";


const initialState = {
  adminData: [],
  totalCount: 0,
  loading: 'idle', // 'idle' | 'pending'
  error: null,
  adminCreated: false,
  isUpdated:false,
};

export const getAllAdmins = createAsyncThunk('@admin/getAllAdmins', async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_GET_ALL_ADMINS}`;
  try {
    const response = await apiGetData(url, params);
    const { pageAdmin, totalCount } = response.data?.response;
    return { pageAdmin, totalCount };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const addAdmin = createAsyncThunk('@admin/add', async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_GET_ALL_ADMINS}`;
  try {
    const response = await apiPostData(url, params);
    
    thunkAPI.dispatch(showToaster({ type: "success", text: "Admin Added Successfully" }));

    const { pageAdmin, totalCount } = response.data?.response;
    return { pageAdmin, totalCount };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateAdmin = createAsyncThunk('@admin/update', async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_GET_ALL_ADMINS}${params.id}`;
  try {
    const { id, name, ...rest } = params;
    const response = await apiUpdateData(url, rest);
    const { pageAdmin, totalCount } = response.data?.response;
    return { pageAdmin, totalCount };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deactivateAdmin = createAsyncThunk('@admin/deactivate', async (data, thunkAPI) => {
  const url = `${API_COMMON}/${API_DEACTIVATE_USER}`;
  try {
    const response = await apiPostData(url, data.data);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const reactivateAdmin = createAsyncThunk('@admin/reactivate', async (data, thunkAPI) => {
  const url = `${API_COMMON}/${API_REACTIVATE_USER}`;
  try {
    const response = await apiPostData(url, data.data);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearAdminState: (state) => {
      state.adminData = [];
      state.totalCount = 0;
    },
    clearAdminAddedFlag: (state) => {
      state.adminCreated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAdmins.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.adminData = action.payload.pageAdmin;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getAllAdmins.rejected, (state, action) => {
      state.loading = 'idle';
      state.adminData = [];
      state.error = action.payload;
    });
    builder.addCase(addAdmin.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.adminCreated = true;
    });
    builder.addCase(addAdmin.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload;
      state.adminCreated = false;
    });
    builder.addCase(updateAdmin.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(updateAdmin.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.adminCreated = true;
    });
    builder.addCase(updateAdmin.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload;
      state.adminCreated = false;
    });
    builder.addCase(deactivateAdmin.pending, (state) => {
      state.loading = 'pending';
      state.isUpdated = false;
    });
    builder.addCase(deactivateAdmin.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload) {
        state.isUpdated = true;
      }
    });
    builder.addCase(deactivateAdmin.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload;
    });

    builder.addCase(reactivateAdmin.pending, (state) => {
      state.loading = 'pending';
      state.isUpdated = false;
    });
    builder.addCase(reactivateAdmin.fulfilled, (state, action) => {
      state.loading = 'idle';
      if (action.payload) {
        state.isUpdated = true;
      }
    });
    builder.addCase(reactivateAdmin.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload;
    });
  },
});

// selectors

export const getAllAdminsSelector = (state) => state.admin && state.admin.adminData;
export const getTotalCountSelector = (state) => state.admin && state.admin.totalCount;
export const getadminCreated = (state) => state.admin && state.admin.adminCreated;
export const adminUpdatedSelector = (state) => state.admin.isUpdated;

export const { clearAdminState, clearAdminAddedFlag } = adminSlice.actions;

export default adminSlice.reducer;
