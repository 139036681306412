import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login } from '../../services/auth.service';
import { apiGetData } from '../../services/api.service';
import { API_COMMON, API_GET_ALL_ADMINS } from '../../constants/apiUrls';

const initialState = {
  auth: null,
  isLoginSuccess: '',
  isLoginError: '',
  loggedInAdminDetails: null,
  hasResetPassword: false,
  loading: 'idle',
};

export const loginUser = createAsyncThunk('@auth/login', async (data, thunkAPI) => {
  try {
    const response = await login(data);
    const responseData = response.data;
    return responseData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAdminDetailsById = createAsyncThunk('@admin/getadminDetails', async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_GET_ALL_ADMINS}/${params}`;
  try {
    const response = await apiGetData(url);
    const loggedInAdminDetails = response.data?.response;
    return loggedInAdminDetails;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => {
      state.token = '';
      state.userId = '';
      state.profileType = '';
      state.isLoginError = false;
      state.isLoginSuccess = false;
      state.loginFailureMessage = null;
      state.loginSuccessMessage = null;
      state.isFirstPasswordReset = null;
      state.loggedInAdminDetails = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoginSuccess = action.payload.status;
      state.loginSuccessMessage = action.payload.statusMessage;
      state.hasResetPassword = action.payload.response.hasResetPassword;
      localStorage.setItem('token', action.payload.response.token);
      localStorage.setItem('userId', action.payload.response.id);
      localStorage.setItem('profileType', action.payload.response.profileType);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoginError = true;
      state.loginFailureMessage = action.payload.statusMessage;
    });

    builder.addCase(getAdminDetailsById.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(getAdminDetailsById.fulfilled, (state, action) => {
      state.loading = 'idle';
      state.loggedInAdminDetails = action.payload;
    });
    builder.addCase(getAdminDetailsById.rejected, (state, action) => {
      state.loading = 'idle';
      state.error = action.payload.statusMessage;
    });
  },
});

export const adminSelector = (state) => state.auth && state.auth.loggedInAdminDetails;
export const { clearState } = authSlice.actions;
export const authSliceHandler = (state) => state.auth;
export const hasResetPasswordSelector = (state) => state.auth.hasResetPassword;
export default authSlice.reducer;
