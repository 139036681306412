/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetData, apiPostData } from "../../services/api.service";
import { API_SKILLS, API_TAGS } from "../../constants/apiUrls";

const initialState = {
  skills: [],
  tags: [],
  loading: "idle", // 'idle' | 'pending'
  error: null,
};

export const fetchAllSkills = createAsyncThunk(
  "@common/getallskills",
  async (thunkAPI) => {
    try {
      const response = await apiGetData(API_SKILLS);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const addNewSkills = createAsyncThunk(
  "@common/addNewskills",
  async (skills, thunkAPI) => {
    try {
      const response = await apiPostData(API_SKILLS, skills);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getTags = createAsyncThunk("@common/getTags", async (thunkAPI) => {
  try {
    const response = await apiGetData(API_TAGS);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    console.log("error", error);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addNewTags = createAsyncThunk(
  "@common/addNewTags",
  async (tags, thunkAPI) => {
    try {
      const response = await apiPostData(API_TAGS, tags);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllSkills.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchAllSkills.fulfilled, (state, action) => {
      state.loading = "idle";
      state.skills = action.payload;
    });
    builder.addCase(fetchAllSkills.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    // add new skills
    builder.addCase(addNewSkills.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addNewSkills.fulfilled, (state, action) => {
      state.loading = "idle";
      state.skills = [...state.skills, action.payload];
    });
    builder.addCase(addNewSkills.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });

    // get tags
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.loading = "idle";
      state.tags = action.payload;
    });
    // add tags
    builder.addCase(addNewTags.fulfilled, (state, action) => {
      state.loading = "idle";
      state.tags = [...state.tags, action.payload];
    });

    builder.addCase(getTags.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(getTags.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });

    builder.addCase(addNewTags.pending, (state) => {
      state.loading = "pending";
    });

    builder.addCase(addNewTags.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });

    // builder.addMatcher(isAnyOf(getTags.pending,addNewTags.pending), (state) => {

    //     state.loading = 'pending';
    // })

    // builder.addMatcher(isAnyOf(getTags.rejected,addNewTags.rejected), (state, action) => {
    //     console.log('here')
    //     state.loading = 'idle';
    //     state.error = action.payload
    // })
  },
});

// selectors
export const skillsSelector = (state) => state.common.skills;
export const skillNamesSelector = (state) =>
  state.common.skills.map((skill) => skill.name);
export const tagSelector = (state) => state.common.tags;
export const getTagNamesVisible = (state) =>
  state.common.tags?.filter((tag) => !tag.hidden).map((tag) => tag.name);
export const getTagNamesHidden = (state) =>
  state.common.tags?.filter((tag) => tag.hidden).map((tag) => tag.name);
// export const {} = commonSlice.actions;

export default commonSlice.reducer;
