import React from "react";
import { exportToExcel } from 'react-json-to-excel';
import PageHeader from "src/components/core/PageHeader";
import PageWrapper from "src/components/core/PageWrapper";
import Title from "src/components/core/Title";
import Icon from '@mdi/react';
import { mdiEmailOutline, mdiPhoneOutline} from '@mdi/js';
import { mdiArrowLeft } from '@mdi/js';
import PageContent from "src/components/core/PageContent";
import { useState } from "react";
import { apiGetData } from "src/services/api.service";
import { API_ADMIN_ROUTE, API_URL_SALES_LEAD } from "src/constants/apiUrls";
import { useEffect } from "react";
import Button from "src/components/core/Button";
import DataTableGrid from "src/components/core/DataGrid";
import NameAvatar from "src/components/core/NameAvatar";
import { dateFormatter } from "src/constants/helper";
import { Modal} from 'react-bootstrap';
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const statusColor = "green";
const thinText = (text) => <p className="mb-0 font_14">{text || <span className="ps-3">--</span>}</p>;

const theme = createTheme({
  components: {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#546c5f',
            borderRadius: '0',
            color: '#fff',
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#546c5f',
            borderRadius: '0',
            color: '#fff',
          },
        },
      },
    },
  },
});

const ViewAllContacts = () => {
    const [queryParams, setQueryParams] = useState({});
    const [search, setSearch] = useState('');
    const [contacts, setContacts] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalContacts, setTotalContacts] = useState(0);
    const [ExcelFileUrl, setExcelFileUrl] = useState(null);
    const EnquiryCell = ({ enquiry }) => {
        if (!enquiry) return null;

        const showModal = () => {
            setModalContent(enquiry);
            setModalShow(true);
        };

        return (
            <div>
                {thinText(enquiry.slice(0, 20))}
                {enquiry.length > 20 && (
                    <>
                        <button onClick={showModal} style={{ color: 'blue', cursor: 'pointer', background: 'none', border: 'none', padding: 0, marginLeft: '5px', fontSize: '12px' }}>...Show More</button>
                    </>
                )}
            </div>
        );
    };

    const fetchExcel = () => {
        const url = `${API_ADMIN_ROUTE}/contactus/downloadExcel`;
        apiGetData(url).then((res) => {
            const fileUrl = res.data.response.contactusData;
            console.log(fileUrl);
            setExcelFileUrl(fileUrl);
        }).catch((error) => {
            console.error("Error fetching Excel file:", error);
        });
    };

    const onDownload = () => {
        if (!ExcelFileUrl) {
            console.error("Excel file URL is not available. Please fetch the data first.");
            return;
        }

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = ExcelFileUrl;
        link.setAttribute('download', 'Contacts-Details.xlsx'); // Use appropriate filename here
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    };

    const fetchSalesLead = (page = 1) => {
        const skip = (page - 1) * pageSize;
        const url = `${API_ADMIN_ROUTE}/contactus/getContactus?skip=${skip}&limit=${pageSize}`;
        apiGetData(url).then((res) => {
            setContacts(res.data.response.contactus);
            setTotalContacts(res.data.response.totalCount);
        });
    };

    

    useEffect(() => {
        fetchSalesLead(currentPage);
        fetchExcel();
    }, [currentPage, pageSize]);

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 230,
            sortable: false,
            renderCell: (params) => (
                <div className="d-flex gap-2 align-items-center ps-3">
                    <NameAvatar size="xs" name={params.row.name} color={statusColor} />
                    {thinText(params.row.name)}
                </div>
            ),
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            renderCell: (params) => (
                <div className="d-flex align-items-center gap-2">
                    <Icon color="#4d4d4d" path={mdiEmailOutline} size={0.6} />
                    {thinText(params.row.email)}
                </div>
            ),
        },
        {
            field: 'city',
            headerName: 'City',
            width: 150,
            renderCell: (params) => thinText(params.row.city),
        },
        {
            field: 'enquiry',
            headerName: 'Enquiry',
            width: 250,
            renderCell: (params) => <EnquiryCell enquiry={params.row.enquiry} />,
        },
        {
            field: 'phoneNumber',
            headerName: 'Phone Number',
            width: 200,
            renderCell: (params) => (
                <div className="d-flex align-items-center gap-2">
                    <Icon color="#4d4d4d" path={mdiPhoneOutline} size={0.6} />
                    {thinText(params.row.phone_number)}
                </div>
            ),
        },
        {
            field: 'dateReceived',
            headerName: 'Date Received',
            width: 200,
            renderCell: (params) => thinText(dateFormatter(new Date(params.row.createdAt).toLocaleDateString())),
        },
    ];

    return (
        <ThemeProvider theme={theme}>
            <PageWrapper>
                <PageHeader>
                    <div className="d-flex justify-content-between gap-3 align-items-center">
                        <Title icon={mdiArrowLeft} title="All Contacts" />
                        <div className="d-flex gap-2 align-items-center">
                            <Button color='primary' text='Download CSV' type="button" onClick={onDownload} />
                        </div>
                    </div>
                </PageHeader>
                <PageContent>
                    <DataTableGrid rowHeight={70} columns={columns} data={contacts} getRowId={(row) => row._id} colorObj={statusColor} compareKey='type' />
                    <div className="d-flex justify-content-end mt-3">
                        <Pagination
                            count={Math.ceil(totalContacts / pageSize)}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </div>
                    <Modal size="lg" centered show={modalShow} onHide={() => setModalShow(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Enquiry Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{modalContent}</Modal.Body>
                    </Modal>
                </PageContent>
            </PageWrapper>
        </ThemeProvider>
    );
};

export default ViewAllContacts;