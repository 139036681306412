import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline, mdiAlertCircleOutline, mdiInformationOutline, mdiAlertOutline, mdiClose } from '@mdi/js';
import Button from '../Button';
import { closeToaster, toastTextSelector, toastTypeSelector } from "../../../redux/slice/toasterSlice";
import styles from './toaster.module.scss';


const Toaster = () => {
    const dispatch = useDispatch();
    const toastType = useSelector(toastTypeSelector);
    const toastText = useSelector(toastTextSelector);
    const toastIcons = {
        success: mdiCheckCircleOutline, error: mdiAlertCircleOutline, info: mdiInformationOutline, warning : mdiAlertOutline
    }
    const color = {success: '#0F945F', error: '#DC3716', info: '#175299', warning : '#FFC44D'};

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(closeToaster())
        }, 1500);

        return () => clearTimeout(timer);
    }, [dispatch, toastText]);

    const onCloseToast = () => {
        dispatch(closeToaster());
    }

    return (
        <div className={`${styles.toaster_container} ${styles[toastType]}`}>
            <div className={styles.toast_icon}> 
                <Icon path={toastIcons[toastType]} size={1.4} color={color[toastType]} />
            </div>
            <div className={styles.toast_content}>
                <p>{toastText}</p>
            </div>
            <Button onClick={onCloseToast} color="linkbtn" link icon={mdiClose} size='sm' iconSize={1} />
        </div>
    )
};

export default Toaster;