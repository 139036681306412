import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_GET_PROJECT_COSTING, API_LATEST_TEMPLATES } from "src/constants/apiUrls";
import { apiGetData } from "src/services/api.service";


const initialState = {
    totalCount: 0,
    templates : [],
    projectCosting: {},
    actualCostTotal: 0,
    estimateCostTotal: 0,
    overallDelta: 0,
    showAllTotal: false,
    error : null
};

export const getAllLatestCostingTemplates = createAsyncThunk('@costing/getAllLatestCostingTemplates', async (params, thunkAPI) => {
    const url = `${API_LATEST_TEMPLATES}`;
    try {
      const response = await apiGetData(url, params);
      const responseData = response.data?.response;
      return responseData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
})

export const getProjectCostingByID = createAsyncThunk('@costing/getProjectCostingByID', async (projectId, thunkAPI) => {
  const url = `${API_GET_PROJECT_COSTING}/${projectId}`;
  try {
    const response = await apiGetData(url);
    const responseData = response.data?.response;
    return responseData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

const costingSlice = createSlice({
    name: "costing",
    initialState,
    reducers : {
      setAllTotalsData: (state, action) => {
        const {payload} = action;
        state.actualCostTotal = payload.actualCostTotal;
        state.estimateCostTotal = payload.estimateCostTotal;
        state.overallDelta = payload.overallDelta;
        state.showAllTotal = true;
      },
      removeAllTotalsData: (state) => {
        state.actualCostTotal = 0;
        state.estimateCostTotal = 0;
        state.overallDelta = 0;
        state.showAllTotal = false;
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllLatestCostingTemplates.pending, (state) => {
            state.loading = "pending";
        })
        builder.addCase(getAllLatestCostingTemplates.fulfilled, (state, action) => {
            state.loading = "idle";
            state.templates = action.payload;
            // state.totalCount = action.payload?.totalCount;
        })
        builder.addCase(getAllLatestCostingTemplates.rejected, (state, action) => {
            state.loading = "idle";
            state.error = action.payload;
        })

        builder.addCase(getProjectCostingByID.pending, (state) => {
            state.loading = "pending";
        })
        builder.addCase(getProjectCostingByID.fulfilled, (state, action) => {
            state.loading = "idle";
            state.projectCosting = action.payload;
        })
        builder.addCase(getProjectCostingByID.rejected, (state, action) => {
            state.loading = "idle";
            state.error = action.payload;
        })
    }
  });


  // selectors
    export const costingTemplatesSelector = (state) => state.costing.templates;
    export const totalCostingTemplateCount = (state) => state.costing.totalCount;
    export const projectCostingSelector = (state) => state.costing.projectCosting;
    export const actualCostTotalSelector = (state) => state.costing.actualCostTotal;
    export const estimateCostTotalSelector = (state) => state.costing.estimateCostTotal;

    export const overallDeltaSelector = (state) => state.costing.overallDelta;
    export const showAllTotalSelector = (state) => state.costing.showAllTotal;

  // actions
    export const {setAllTotalsData, removeAllTotalsData} = costingSlice.actions;


  export default costingSlice.reducer;