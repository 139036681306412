import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiAccountCog, mdiViewDashboardVariantOutline, mdiFileChart, mdiCurrencyRupee, mdiContentPaste, mdiBellOutline, mdiAccountDetailsOutline, mdiTune, mdiLogout, mdiShieldAccount, 
  // mdiCogOutline,
  // mdiHelpCircleOutline, 
  // mdiFaceAgent,
  mdiNoteEditOutline,  
  mdiCalendarBlankOutline } from '@mdi/js';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './navSide.module.scss';
import { URL_CALENDAR, URL_CONTENT_REVIEW, URL_META_DETAILS, URL_NOTIFICATION, URL_OPERATIONS_DASHBOARD, URL_PAYMENT_PLAN, URL_PROJECT_MANAGEMENT_DASHBOARD,  URL_SALES_DASHBOARD, URL_USER_ACTIVITY, URL_USER_MANAGEMENT,
  // URL_CUSTOMER_SERVICE,
  // URL_HELP,
  // URL_PROJECT_MANAGEMENT,
  // URL_SETTINGS,
  // URL_SITE_MANAGER,
} from '../../../constants/routeUrl';
import logo from '../../../assets/images/logoIcon.svg';
import logoText from '../../../assets/images/logoWhite.svg';
import { useDispatch, useSelector } from 'react-redux';
import { clearState } from 'src/redux/slice/authSlice';
import { showToaster } from 'src/redux/slice/toasterSlice';
import { getUserById, modulePermissionSelector, userSelector} from 'src/redux/slice/userSlice';

const NavSide = () => {
  const [isExpand, setIsExpand] = useState(false);
  const userId = localStorage.getItem('userId');
  const permissions = useSelector(modulePermissionSelector);
  const user = useSelector(userSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleHover = () => {
    setIsExpand(true);
  };
  const handleLeave = () => {
    setIsExpand(false);
  };

  const onLogout = () => {
    dispatch(clearState());
    dispatch(
      showToaster({ type: 'success', text: 'Logged out successfully !!!' })
    );
    navigate('/', { replace: true });
  };

  // route links should come from constants folder -- pending
  const topLinks = [
    { id: 1, icon: mdiAccountCog, link: URL_USER_MANAGEMENT, text: 'User Management', permission: 'user_management' },
    { id: 2, icon: mdiFileChart, link: URL_SALES_DASHBOARD, text: 'Sales Dashboard', permission: 'sales'},
    { id: 3, icon: mdiViewDashboardVariantOutline, link: URL_OPERATIONS_DASHBOARD, text: 'Operations Dashboard', permission: 'operations'},
    { id: 4, icon: mdiCurrencyRupee, link: URL_PAYMENT_PLAN, text: 'Payment Plan', permission: 'payment_plan' },
    { id: 5, icon: mdiContentPaste, link: URL_CONTENT_REVIEW, text: 'Content Reviewer', permission: 'content'},
    { id: 6, icon: mdiTune, link: URL_PROJECT_MANAGEMENT_DASHBOARD, text: 'Project Management', permission: 'pmc'},
    { id: 7, icon: mdiAccountDetailsOutline, link: URL_USER_ACTIVITY, text: 'User Activity Dashboard', permission: 'user_activity'},
    { id: 8, icon: mdiNoteEditOutline, link: URL_META_DETAILS, text: 'SEO Details', permission: 'content'},
    // { id: 8, icon: mdiFaceAgent, link: URL_CUSTOMER_SERVICE, text: 'Customer Service Dashboard' },
    // { id: 9, icon: mdiNoteEditOutline, link: URL_SITE_MANAGER, text: 'Site Manager' },
  ];

  const bottomLinks = [
    { id: 10, icon: mdiCalendarBlankOutline, link: URL_CALENDAR, text: 'Calendar'},
    { id: 11, icon: mdiBellOutline, link: URL_NOTIFICATION, text: 'Notification',
    //  count: 20
    },
    // { id: 12, icon: mdiCogOutline, link: URL_SETTINGS, text: 'Settings' },
    // { id: 13, icon: mdiHelpCircleOutline, link: URL_HELP, text: 'Help/FAQ’s' },
  ];


  useEffect(() => {
    dispatch(getUserById(userId));
  }, [userId]);


  return (
    <div className={`${styles.sidebar_container} ${isExpand && styles.expanded}`} onMouseOver={handleHover} onMouseLeave={handleLeave} >
      <div className={styles.sidebar_header}>
        <div>
          <img className={`${styles.brand} ${isExpand ? styles.expandedLogo : ''}`} src={isExpand ? logoText : logo} alt="Brand logo" />
        </div>
      </div>
      <div className={styles.top_content}>
        <div className={`${styles.side_link} ${styles.user_profile}` }>
          <div style={{margin: 'auto'}}>
            <Icon path={mdiShieldAccount} size={1.6} color="#fff" />
          </div>
            <div className={styles.hide_if_not_expanded}>
              <div className={`${styles.profile_content} d-flex justify-content-between align-items-center`}>
                <div className='flex-grow-1'>
                  <p className={`${styles.name} text-white mb-0`}>{user?.profileMeta?.name}</p>
                  <p className='mb-0 text-white font_12'>{user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        <div className={styles.links_container}>
          {permissions && topLinks.map((link) => permissions[link.permission] !== 'na' ?
          <NavLink key={link.id} to={link.link} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active}` : styles.side_link } >
            <Icon path={link.icon} size={1} color="#fff" />
            <div className={styles.hide_if_not_expanded}>
              <p className={styles.link_text}>{link.text}</p>
            </div>
          </NavLink> : null
          )}
        </div>
      </div>
      <div className={styles.bottom_content}>
        {bottomLinks.map((link) => (
          <NavLink key={link.id} to={link.link} className={({ isActive }) => isActive ? `${styles.side_link} ${styles.active}` : styles.side_link } >
            <Icon path={link.icon} size={1} color="#fff" />
            <div className={styles.hide_if_not_expanded}>
              <p className={`${styles.link_text} ${ link.count ? 'd-flex justify-content-between align-items-center' : '' }`} >
                {link.text} {link.count && <span>20</span>}
              </p>
            </div>
            {link.count && !isExpand && <div className={styles.dot} />}
          </NavLink>
        ))}
        <div className={styles.side_link}>
          <Icon path={mdiLogout} size={1} color="#fff" />
          <div className={styles.hide_if_not_expanded}>
            <p className={`${styles.link_text}`} onClick={onLogout}>
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavSide;
