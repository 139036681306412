/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_ADMIN,
  API_COMMON,
  API_GENERATE_PROJECT_QUOTATION,
  // API_GET_ALL_LEADS,
  API_GET_EXPERT_BY_STATUS,
  API_LEAD,
  API_SEND_PROJECT_QUOTATION,
} from "../../constants/apiUrls";
// import { getUserId } from "../../constants/helper";
import { apiGetData, apiPostData, apiUpdateData } from "../../services/api.service";
import { showToaster } from "./toasterSlice";
import { store } from "../store/store";

const initialState = {
  leads: [],
  statuses: [
    {
      status: "Requested",
      value: "requested",
    },
    {
      status: "Link Sent",
      value: "accepted",
    },
    {
      status: "Sign-Up",
      value: "sign_up",
    },
    {
      status: "Contract",
      value: "contract_sent",
    },
    {
      status: "Converted",
      value: "active",
    },
    { status: "Rejected", value: "rejected" },
  ],
  loading: "idle", // 'idle' | 'pending'
  error: null,
};

export const getLeads = createAsyncThunk("@lead/getLeads", async (thunkAPI) => {
  const url = `${API_COMMON}/${API_ADMIN}${API_GET_EXPERT_BY_STATUS}`;
  try {
    const response = await apiGetData(url);
    if (response.status === 200) {
      const resposeData = response.data?.response;
      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateLeads = createAsyncThunk("@lead/updateLeads", async ({ id, body }, thunkAPI) => {
  const url = `${API_COMMON}/${API_ADMIN}/${id}`;
  try {
    const response = await apiUpdateData(url, body);
    if (response.status === 200) {
      const resposeData = response.data?.response;
      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateAdminLead = createAsyncThunk("@lead/updateAdminLead", async ({ id, body }, thunkAPI) => {
  const url = `${API_COMMON}/${API_LEAD}/${id}`;
  try {
    const response = await apiUpdateData(url, body);
    if (response.status === 200) {
      const resposeData = response.data?.response;
      return resposeData;
    }
    return [];
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const generateProjectQuotation = createAsyncThunk(
  "@admin/generateProjectQuotation",
  async (leadId, thunkAPI) => {
    const url = `${API_COMMON}/${API_GENERATE_PROJECT_QUOTATION}`;
    try {
      const response = await apiPostData(url, { leadId });
      const resposeData = response.data?.response;
      store.dispatch(showToaster({ type: "success", text: "Quotation Generated Succesfully" }));
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const sendProjectQuotation = createAsyncThunk(
  "@admin/sendProjectQuotation",
  async (leadId, thunkAPI) => {
    const url = `${API_COMMON}/${API_SEND_PROJECT_QUOTATION}`;
    try {
      const response = await apiPostData(url, { leadId });
      const resposeData = response.data?.response;
      store.dispatch(showToaster({ type: "success", text: "Quotation Sent Succesfully" }));
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const leadSlice = createSlice({
  name: "lead",
  initialState,
  // reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getLeads.fulfilled, (state, action) => {
      state.loading = "idle";
      state.leads = action.payload;
    });
    builder.addCase(getLeads.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(updateLeads.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(updateLeads.fulfilled, (state, action) => {
      state.loading = "idle";
      const currentItem = action.payload;
      const newItems = state.leads
        .filter((i) => i._id !== currentItem._id)
        .concat({ ...currentItem });
      state.leads = [...newItems];
    });
    builder.addCase(updateLeads.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors
export const leadsSelector = (state) => state.lead.leads;
export const leadStatuses = (state) => state.lead.statuses;

// export const {deleteBlog} = leadSlice.actions

export default leadSlice.reducer;
