import React from "react";
import Icon from "@mdi/react";
import { useNavigate } from "react-router-dom";
import styles from "./title.module.scss";
import { capitalizeWord } from "src/constants/helper";

const Title = ({ icon, title, themeColor, tilestyle,iconSize=1.5 }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  return (
    <div className={styles.heading}>
      {icon && <Icon onClick={goBack} path={icon} size={iconSize} color="#546C5F" style={{cursor: 'pointer'}} />}
      <h2 style={tilestyle} className={`${styles.title} ${themeColor ? styles.theme : ""}`}>
        {capitalizeWord(title) }
      </h2>
    </div>
  );
}

export default Title;