import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_PROJECT } from "src/constants/apiUrls";
import { apiGetData} from "src/services/api.service";

const initialState = {
    totalCount: 0,
    ongoingProjects : [],
    error : null
}

export const getOngoingProjects = createAsyncThunk('@projects/getAll', async(params, thunkAPI) => {
   const url = `${API_PROJECT}`;
   try {
    const response = await apiGetData(url, params);
    const responseData = response.data?.response;
    return responseData;
   } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
   }
})


const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers : { },
    extraReducers: (builder) => {
        builder.addCase(getOngoingProjects.pending, (state) => {
            state.loading = "pending";
        })
        builder.addCase(getOngoingProjects.fulfilled, (state, action) => {
            state.loading = "idle";
            state.ongoingProjects = action.payload;
        })
        builder.addCase(getOngoingProjects.rejected, (state, action) => {
            state.loading = "idle";
            state.error = action.payload;
        })
}
})

export const projectsSelector = (state) => state.projects.ongoingProjects;
// export const totalTemplateCount = (state) => state.templates.totalCount;
export default projectSlice.reducer;