import React from "react";
import styles from "./nocontent.module.scss";

const NoContent = ({ src, title, description, children, isSmall=false }) => {
  return (
    <div className={styles.nocontent_wrapper}>
      <div className={styles.content}>
        {src && (
          <div className={`${styles.image} ${isSmall ? styles.small : ''}`}>
            <img src={src} alt="placeholder" />
          </div>
        )}
        {title && <p className={styles.title}>{title}</p>}
        {description && <p className={styles.description}>{description}</p>}
        {children && children}
      </div>
    </div>
  );
}

export default NoContent;
