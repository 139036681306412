import axiosInstance from "../utils/axiosInstance";


export const apiGetData = (url, config = {}) => {
  const _config= {headers: { "content-type": "application/json", Accept: "application/json" }, ...config}
  return axiosInstance.get(url, _config);
};

export const apiPostData = (url, body, config = {}) => {
  const _config= {headers: { "content-type": "application/json", Accept: "application/json" }, ...config}
  return axiosInstance.post(url, body, _config);
};

export const apiUpdateData = (url, body, config = {}) => {
  return axiosInstance.put(url, body, config);
};

export const apiDeleteData = (url) => {
  return axiosInstance.delete(url);
};

