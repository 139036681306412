/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  API_GET_EXPERTS,
  API_GET_ALL_EXPERTS,
  API_COMMON,
  API_DEACTIVATE_USER,
  API_REACTIVATE_USER,
  API_CREATE_EXPERTS,
  API_ADMIN,
  API_REQUEST_TO_JOIN,
  API_GET_EXPERT_BY_STATUS,
  API_GET_ADMIN_EXPERT,
  API_GET_EXPERT_REQUESTS,
  API_GENERATE_EXPERT_CONTRACT,
  API_SEND_EXPERT_CONTRACT,
} from "../../constants/apiUrls";
import { apiGetData, apiPostData } from "../../services/api.service";
import { store } from "../store/store";
import { showToaster } from "./toasterSlice";

const initialState = {
  experts: null,
  loading: "idle", // 'idle' | 'pending'
  error: null,
  addExperts: null,
  isDeleted: false,
  isUpdated: false,
  expertByStatus: null,
};

export const getExperts = createAsyncThunk("@user/getExperts", async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_ADMIN}${API_GET_ADMIN_EXPERT}`;
  try {
    const response = await apiGetData(url, params);
    const { experts, totalCount } = response.data?.response;

    return { experts, totalCount };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const getAllExperts = createAsyncThunk("@user/getAllExperts", async (params, thunkAPI) => {
  const url = `${API_COMMON}/${API_GET_ALL_EXPERTS}`;
  try {
    const response = await apiGetData(url, params);
    const { experts, totalCount } = response.data?.response;

    return { experts, totalCount };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deactivateExpert = createAsyncThunk("@user/deactivate", async (data, thunkAPI) => {
  const url = `${API_COMMON}/${API_DEACTIVATE_USER}`;
  try {
    const response = await apiPostData(url, data.data);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const reactivateExpert = createAsyncThunk("@user/reactivate", async (data, thunkAPI) => {
  const url = `${API_COMMON}/${API_REACTIVATE_USER}`;
  try {
    const response = await apiPostData(url, data.data);
    const resposeData = response.data?.response;
    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const createExpert = createAsyncThunk("@admin/createExpert", async (data, thunkAPI) => {
  const url = `${API_COMMON}/${API_ADMIN}${API_CREATE_EXPERTS}`;
  try {
    const response = await apiPostData(url, data.data);
    const resposeData = response.data?.response;
    store.dispatch(showToaster({ type: "success", text: "Created Expert and Link Sent" }));

    return resposeData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const expertRequestToJoin = createAsyncThunk(
  "@admin/expertRequestToJoin",
  async (userId, thunkAPI) => {
    const url = `${API_COMMON}/${API_ADMIN}${API_REQUEST_TO_JOIN}`;
    try {
      const response = await apiPostData(url, { userId });
      const resposeData = response.data?.response;
      store.dispatch(showToaster({ type: "success", text: " Invitation email sent" }));

      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const generateExpertContract = createAsyncThunk(
  "@admin/generateExpertContract",
  async (expertId, thunkAPI) => {
    const url = `${API_COMMON}/${API_ADMIN}${API_GENERATE_EXPERT_CONTRACT}`;
    try {
      const response = await apiPostData(url, { expertId });
      const resposeData = response.data?.response;
      store.dispatch(showToaster({ type: "success", text: "Contract Generated Succesfully" }));
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const sendExpertContract = createAsyncThunk(
  "@admin/sendExpertContract",
  async (expertId, thunkAPI) => {
    const url = `${API_COMMON}/${API_ADMIN}${API_SEND_EXPERT_CONTRACT}`;
    try {
      const response = await apiPostData(url, { expertId });
      const resposeData = response.data?.response;
      store.dispatch(showToaster({ type: "success", text: "Contract Sent Succesfully" }));
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getExpertByStatus = createAsyncThunk(
  "@admin/getExpertByStatus",
  async (thunkAPI, params) => {
    const url = `${API_COMMON}/${API_ADMIN}${API_GET_EXPERT_BY_STATUS}`;
    try {
      const response = await apiGetData(url, params);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getExpertByRequest = createAsyncThunk(
  "@admin/getExpertByRequest",
  async (thunkAPI, params) => {
    const url = `${API_COMMON}/${API_ADMIN}${API_GET_EXPERT_REQUESTS}`;
    try {
      const response = await apiGetData(url, params);
      const resposeData = response.data?.response;
      return resposeData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const expertSlice = createSlice({
  name: "expert",
  initialState,
  reducers: {
    clearExpertState: (state) => {
      state.expertData = [];
      state.totalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExperts.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getExperts.fulfilled, (state, action) => {
      state.loading = "idle";
      state.experts = action.payload;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getExperts.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(getAllExperts.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getAllExperts.fulfilled, (state, action) => {
      state.loading = "idle";
      state.expertData = action.payload.experts;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getAllExperts.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(deactivateExpert.pending, (state) => {
      state.loading = "pending";
      state.isUpdated = false;
    });
    builder.addCase(deactivateExpert.fulfilled, (state, action) => {
      state.loading = "idle";
      if (action.payload) {
        state.isUpdated = true;
      }
    });
    builder.addCase(deactivateExpert.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });

    builder.addCase(reactivateExpert.pending, (state) => {
      state.loading = "pending";
      state.isUpdated = false;
    });
    builder.addCase(reactivateExpert.fulfilled, (state, action) => {
      state.loading = "idle";
      if (action.payload) {
        state.isUpdated = true;
      }
    });
    builder.addCase(reactivateExpert.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(createExpert.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createExpert.fulfilled, (state, action) => {
      state.loading = "idle";
      state.addExperts = action.payload;
    });
    builder.addCase(createExpert.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
    builder.addCase(getExpertByRequest.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getExpertByRequest.fulfilled, (state, action) => {
      state.loading = "idle";
      state.expertByStatus = action.payload;
    });
    builder.addCase(getExpertByRequest.rejected, (state, action) => {
      state.loading = "idle";
      state.error = action.payload;
    });
  },
});

// selectors
export const expertsSelector = (state) => state.expert.experts?.experts;
export const getExpertTotalCountSelector = (state) => state.expert.totalCount;
export const expertUpdatedSelector = (state) => state.expert.isUpdated;
export const getAllExpertSelector = (state) => state.expert && state.expert.expertData;
// export const expertDeletedSelector = (state) => state.expert.isDeleted;
export const getTotalCountSelector = (state) => state.expert.totalCount;
export const allExpertByStatusSelector = (state) => state.expert.expertByStatus;
// export const {} = expertSlice.actions
// export const { } = expertSlice.actions;
export const { clearExpertState } = expertSlice.actions;

export default expertSlice.reducer;
