
import React from "react";
import NavSide from "../sidebar/NavSide";
import styles from './layoutTemplate.module.scss';

const LayoutTemplate = ({layout, children}) => {

    return(
        <div className={`${styles.routesContainer} ${styles[layout]}`}>
            {children}
            {layout === 'private' && <NavSide />}
        </div>
    )
};

export default LayoutTemplate;