//Auth/Test URLs
export const URL_PROFILE = "/profile";
export const URL_HOME = "/";
export const URL_LOGIN = "/login";
export const URL_LOGOUT = "logout";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_RESET_PASSWORD = "/public/auth/reset-password";
export const URL_PRODUCTS = "/products";

export const URL_PAGE_NOT_FOUND = "/page-not-found";

// _______________Util URLs * Util URLs______________________
export const URL_ADD = "/add";
export const URL_EDIT = "/edit";
export const URL_DETAIL = "/detail";
export const URL_LIST = "/list";
export const PARAM_ID = "/:id";

// _______________Project Management * PROJECT MANAGEMENT______________________
export const URL_PROJECT_MANAGEMENT_DASHBOARD = "/project-dashboard";
export const URL_PROJECT_MANAGEMENT = "/project-management";
export const URL_PROJECT_DETAIL = `${URL_PROJECT_MANAGEMENT}${URL_DETAIL}`;
export const URL_PROJECT_ADD = `${URL_PROJECT_MANAGEMENT}${URL_ADD}`;
export const URL_PROJECT_EDIT = `${URL_PROJECT_MANAGEMENT}${URL_EDIT}`;

// _______________User Management * USER MANAGEMENT______________________
export const URL_USER_MANAGEMENT = "/user-management";
export const URL_USER_PROFILE = "/user-profile";
export const URL_USER_MANAGEMENT_USER_PROFILE = `${URL_USER_MANAGEMENT}${URL_USER_PROFILE}${PARAM_ID}`;

// _______________Template Management * PROJECT MANAGEMENT______________________
export const URL_TEMPLATE_MANAGEMENT = "/template-management";
export const URL_CHECKLIST_TEMPLATE = "/checklist-template";
export const URL_CHECKLIST_TEMPLATE_DETAILS = `${URL_CHECKLIST_TEMPLATE}`;

// _______________Content Reviewer______________________
export const URL_CONTENT_REVIEW = "/content-review";
export const URL_ALL_FEEDS = "/feeds";
export const URL_ALL_ACTIVITY = "/activities";
export const URL_ALL_BLOGS = "/blogs";
export const URL_ALL_ARTICLES = "/articles";
export const URL_BLOG = "/blog";
export const URL_PORTFOLIO = "/portfolio";
export const URL_ARTICLE = "/article";
export const URL_ARTICLE_ADD = `${URL_ARTICLE}${URL_ADD}`;
export const URL_ARTICLE_EDIT = `${URL_ARTICLE}${URL_EDIT}`;
export const URL_BLOG_DETAILS = `${URL_CONTENT_REVIEW}${URL_BLOG}${PARAM_ID}`;
export const URL_ARTICLE_DETAILS = `${URL_CONTENT_REVIEW}${URL_ARTICLE}${PARAM_ID}`;
export const URL_PORTFOLIO_DETAILS = `${URL_CONTENT_REVIEW}${URL_PORTFOLIO}${PARAM_ID}`;


// _______________Sidebar URLs______________________

export const URL_USER_ACTIVITY = "/user-activity";
export const URL_SALES_DASHBOARD = "/sales-dashboard";
export const URL_OPERATIONS_DASHBOARD = "/operations-dashboard";
export const URL_PAYMENT_PLAN = "/payment-plan";
export const URL_CUSTOMER_SERVICE = "/customer-service";
export const URL_SITE_MANAGER = "/site-manager";
export const URL_EDIT_PAGES = `${URL_SITE_MANAGER}/edit-pages`;
export const URL_CALENDAR = "/calendar";
export const URL_NOTIFICATION = "/notifications";
export const URL_SETTINGS = "/settings";
export const URL_HELP = "/help";
export const URL_META_DETAILS = '/seo-details'

//_________________________OPERATION DASHBOARD__________________________
export const URL_ALL_EXPERTS = "/operations-dashboard/all-expert";
export const URL_NEW_EXPERTS = "/operations-dashboard/new-experts";
export const URL_ALL_NOTIFICATION = "/notification";
export const URL_EXPERT_PROFILE = "/expert-profile";
export const URL_OPERATIONS_EXPERT_PROFILE = `${URL_OPERATIONS_DASHBOARD}${URL_EXPERT_PROFILE}${PARAM_ID}`;
export const URL_OPERATIONS_ONGOING_PROJECT = `${URL_OPERATIONS_DASHBOARD}/ongoing-project`;

//__________________USER ACTIVITY_________________________

export const URL_USER_ACTIVITY_DETAILS = `${URL_USER_ACTIVITY}/details`;

//__________________PAYMENT PLAN_________________________
export const URL_PAYMENT = "/payment";
export const URL_PAYMENT_SCHEDULE = "/schedule-payment";
export const URL_PAYMENT_URL_PAYMENT_SCHEDULE = `${URL_PAYMENT_PLAN}${URL_PAYMENT_SCHEDULE}${PARAM_ID}`;

//__________________SALES DASHBOARD_________________________

export const ALL_LEADS = "/view-all";
export const ALL_CONTACTS = "/view-all-contacts";

//__________________MEETING_________________________

export const URL_MEETINGS = "/meeting";
export const URL_MEETINGS_ADD = `${URL_MEETINGS}${URL_ADD}`;
export const URL_MEETINGS_EDIT = `${URL_MEETINGS}${URL_EDIT}${PARAM_ID}`;
