import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LayoutTemplate from "./components/layout/LayoutTemplate";
import PrivateRoutes from "./components/layout/routers/PrivateRoute";
import Spinner from "./components/core/Spinner";
import styles from "./app.module.scss";
import Toaster from "./components/core/Toaster";
import { useSelector } from "react-redux";
import { toasterStatusSelector } from "./redux/slice/toasterSlice";
import { loaderStatusSelector } from "./redux/slice/loaderSlice";
import { PARAM_ID, URL_ALL_ARTICLES, URL_ALL_FEEDS, URL_BLOG_DETAILS, URL_CONTENT_REVIEW, URL_HOME, URL_LOGIN, URL_PRODUCTS,URL_PROJECT_ADD, URL_PROJECT_DETAIL, URL_PROJECT_EDIT, URL_PROJECT_MANAGEMENT, URL_USER_MANAGEMENT, URL_OPERATIONS_DASHBOARD, URL_USER_MANAGEMENT_USER_PROFILE, URL_PORTFOLIO_DETAILS, URL_ALL_ACTIVITY, URL_ALL_EXPERTS, URL_NEW_EXPERTS, URL_OPERATIONS_EXPERT_PROFILE, URL_SALES_DASHBOARD, URL_FORGOT_PASSWORD, URL_RESET_PASSWORD, URL_ARTICLE_DETAILS, URL_PAYMENT_PLAN, URL_USER_ACTIVITY, URL_USER_ACTIVITY_DETAILS, URL_PAYMENT_URL_PAYMENT_SCHEDULE, URL_PROJECT_MANAGEMENT_DASHBOARD, URL_TEMPLATE_MANAGEMENT, URL_BLOG, URL_PORTFOLIO, URL_CHECKLIST_TEMPLATE_DETAILS, URL_OPERATIONS_ONGOING_PROJECT, ALL_LEADS, URL_CALENDAR, URL_MEETINGS_ADD, URL_MEETINGS_EDIT, URL_ARTICLE_EDIT, URL_ARTICLE_ADD, URL_PAGE_NOT_FOUND, URL_NOTIFICATION, URL_SITE_MANAGER, URL_EDIT_PAGES, URL_META_DETAILS, ALL_CONTACTS} from "./constants/routeUrl";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { modulePermissionSelector } from "./redux/slice/userSlice";
import ViewAllContacts from "./components/features/salesDashboard/viewAllContacts";

const Home = lazy(() => import("./pages/home/Home"));
// const Profile = lazy(() => import("./pages/profile/Profile"));
const Products = lazy(() => import("./pages/products/Products"));
const Login = lazy(() => import("./pages/login/Login"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/forgotPassword/ResetPassword"));
const PmDashboard = lazy(() => import("./pages/pmDashboard"));
const ProjectManagement = lazy(() => import("./pages/projectManagement"));
const NewProject = lazy(() => import("./pages/newProject"));
const UserManagement = lazy(() => import("./pages/userManagement"));
const ProjectDetails = lazy(() => import("./pages/projectDetailPage"));
const UserProfile = lazy(() => import("./pages/userProfile"));
const ExpertProfile = lazy(() => import("./pages/expertProfile"));
const ContentReviewer = lazy(() => import("./pages/contentReviewer"));
const AllArticles = lazy(() => import("./pages/allArticles"));
const AllFeeds = lazy(() => import("./pages/contentReviewer/allFeeds"));
const BlogDetails = lazy(() => import("./pages/contentReviewer/blogDetails"));
const PortfolioDetails = lazy(() => import("./pages/contentReviewer/portfolioDetails"));
const AllActivities = lazy(() => import("./pages/allActivities"));
const operationsDashboard = lazy(() => import("./pages/operationsDashboard"));
const AllExperts = lazy(() => import("./pages/allExperts"));
const leads = lazy(() => import("./pages/leads"));
const SalesDashboard = lazy(() => import("./pages/salesDashoard"));
const AddEditArticle = lazy(() => import("./pages/contentReviewer/AddEditArcticle/index"));
const PaymentPlanDashboard = lazy(() => import("./pages/paymentPlan"));
const PaymentSchedule = lazy(() => import("./pages/paymentSchedule"));
const SalesDetails = lazy(() => import("./pages/salesDashoard/salesDetails"));
const TemplateManagement = lazy(() => import("./pages/templateManagement"));
const UserActivity = lazy(() => import("./pages/userActivity"));
const UserActivityDetails = lazy(() => import("./pages/userActivity/userActivityDetails"));
const ChecklistTemplateDetails = lazy(() => import("./pages/templateManagement/checklistTemplateDetails"));
const OperationBlogDetails = lazy(() => import("./pages/operationsDashboard/operationBlogDetails"));
const OperationPortfolioDetails = lazy(() => import("./pages/operationsDashboard/operationPortfolioDetails"));
const OngoingProject = lazy(() => import("./components/features/operationsDashboard/OngoingProject"));
const ViewAllLeads = lazy(() => import("./components/features/salesDashboard/viewAllLead"));
const Calendar = lazy(() => import("./pages/Calendar"));
const AddEditMeeting = lazy(() => import("./pages/meeting"));
const PageNotFound = lazy(() => import("./pages/page4O4"));
const Notifications = lazy(() => import("./pages/notifications"));
// const SiteManager = lazy(() => import("./pages/siteManager"));
const EditPages = lazy(() => import("./pages/editPages"));
const AddEditMeta = lazy(()=> import ("./pages/contentReviewer/metaDetails") );
const viewAllContacts = lazy(()=> import ("./components/features/salesDashboard/viewAllContacts") );



const protectedRoutes = [
  { path: URL_HOME, component: Home },
  { path: URL_PAGE_NOT_FOUND, component: PageNotFound },
  { path: URL_PRODUCTS, component: Products },
  { path: URL_PROJECT_MANAGEMENT_DASHBOARD, component: PmDashboard, permission: 'pmc' },
  { path: URL_PROJECT_MANAGEMENT, component: ProjectManagement, permission: 'pmc' },
  { path: URL_PROJECT_ADD, component: NewProject, permission: 'pmc' },
  { path: `${URL_PROJECT_EDIT}${PARAM_ID}`, component: NewProject, permission: 'pmc' },

  { path: URL_USER_MANAGEMENT, component: UserManagement },
  { path: URL_NOTIFICATION, component: Notifications },
  // { path: URL_SITE_MANAGER, component: SiteManager },
  { path: URL_EDIT_PAGES, component: EditPages },
  { path: URL_SALES_DASHBOARD, component: SalesDashboard },
  { path: `${URL_SALES_DASHBOARD}${PARAM_ID}`, component: SalesDetails },
  { path: URL_PROJECT_DETAIL, component: ProjectDetails },
  { path: URL_USER_MANAGEMENT_USER_PROFILE, component: UserProfile },
  { path: URL_OPERATIONS_EXPERT_PROFILE, component: ExpertProfile },
  { path: URL_CONTENT_REVIEW, component: ContentReviewer },
  { path: `${URL_CONTENT_REVIEW}${URL_ALL_ARTICLES}`, component: AllArticles },
  { path: `${URL_CONTENT_REVIEW}${URL_ALL_FEEDS}`, component: AllFeeds },
  { path: URL_BLOG_DETAILS, component: BlogDetails },
  { path: URL_ARTICLE_DETAILS, component: BlogDetails },
  { path: URL_PORTFOLIO_DETAILS, component: PortfolioDetails },
  { path: URL_ALL_ACTIVITY, component: AllActivities },
  { path: `${URL_PROJECT_DETAIL}${PARAM_ID}`, component: ProjectDetails },
  { path: URL_OPERATIONS_DASHBOARD, component: operationsDashboard },
  { path: URL_ALL_EXPERTS, component: AllExperts },
  { path: URL_NEW_EXPERTS, component: leads },
  { path: URL_ARTICLE_ADD, component: AddEditArticle },
  { path: `${URL_ARTICLE_EDIT}${PARAM_ID}`, component: AddEditArticle },
  { path: URL_PAYMENT_PLAN, component: PaymentPlanDashboard, permission: 'payment_plan'},
  { path: URL_USER_ACTIVITY, component: UserActivity },
  { path: URL_USER_ACTIVITY_DETAILS, component: UserActivityDetails },
  { path: URL_PAYMENT_URL_PAYMENT_SCHEDULE, component: PaymentSchedule },
  { path: URL_TEMPLATE_MANAGEMENT, component: TemplateManagement },
  { path: `${URL_CHECKLIST_TEMPLATE_DETAILS}${PARAM_ID}`, component: ChecklistTemplateDetails},
  { path: `${URL_OPERATIONS_DASHBOARD}${URL_BLOG}${PARAM_ID}`, component: OperationBlogDetails},
  { path: `${URL_OPERATIONS_DASHBOARD}${URL_PORTFOLIO}${PARAM_ID}`, component: OperationPortfolioDetails},
  { path: `${URL_OPERATIONS_ONGOING_PROJECT}${PARAM_ID}`, component: OngoingProject},
  { path: `${URL_SALES_DASHBOARD}${ALL_LEADS}`, component: ViewAllLeads },
  { path: URL_CALENDAR, component: Calendar },
  { path: URL_MEETINGS_ADD, component: AddEditMeeting },
  { path: URL_MEETINGS_EDIT, component: AddEditMeeting },
  { path: URL_META_DETAILS, component: AddEditMeta},
  { path: `${URL_SALES_DASHBOARD}${ALL_CONTACTS}`, component: viewAllContacts}
  
];

const publicRoutes = [
  { path: URL_LOGIN, component: Login },
  { path: URL_FORGOT_PASSWORD, component: ForgotPassword },
  { path: `${URL_RESET_PASSWORD}/:id`, component: ResetPassword }
];



const App = () => {
  const showToast = useSelector(toasterStatusSelector);
  const showLoader = useSelector(loaderStatusSelector);
  const permissions = useSelector(modulePermissionSelector);
  ChartJS.register(ArcElement, Tooltip, Legend);

  const filteredRoutes = permissions ? protectedRoutes?.filter(route => (!route.permission || (route.permission && permissions[route.permission] !== 'na'))) : protectedRoutes;

  return (
    <div className={`${styles.mainContainer}`}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <Suspense fallback={<Spinner />}>
                <LayoutTemplate layout="public">
                  <route.component />
                </LayoutTemplate>
              </Suspense>
            }
            {...route}
          />
        ))}


        {filteredRoutes.length > 0 && 
          <Route element={<PrivateRoutes />}>
          {filteredRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<Spinner />}>
                  <LayoutTemplate layout="private">
                    <route.component />
                  </LayoutTemplate>
                </Suspense>
              }
              {...route}
            />
          ))}
         <Route path="*" element={<Navigate to={URL_PAGE_NOT_FOUND} replace />} />
        </Route>
        }
        
      </Routes>

      {showToast && <Toaster />}
      {showLoader && <Spinner isGlobal />}
    </div>
  );
};

export default App;



