import React from "react";
import Icon from "@mdi/react";
import styles from "./button.module.scss";

const Button = ({
  text,
  color,
  icon,
  link,
  iconRight= false,
  type = "submit",
  onClick,
  size,
  className,
  rounded = false,
  iconSize = 0.75,
  isDashed= false,
  disable=false,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/button-has-type
    <button disabled={disable} className={`${styles.btn} ${styles[color]} ${styles[size]} ${ link ? styles.linkBtn : "" } ${!text && icon ? styles.onlyIcon : ""} ${ rounded ? styles.rounded : "" } ${iconRight ? styles.icon_right : ''} ${isDashed && styles.dashed} ${className}`}
      // eslint-disable-next-line react/button-has-type
      type={type || "button"}
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <Icon
          path={icon}
          size={iconSize}
          color={
            link || color === "bordered" || color === "light"
              ? "#546C5F"
              : "#fff"
          }
        />
      )}
      {text && text}
    </button>
  );
};

export default Button;
